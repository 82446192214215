// to ignore cli warning about deprecated mixin
$enable-deprecation-messages: false;
$ignore-warning             : true;

// Color
$primary   : #25B8CC;
$secondary : #FF7468 !default;
$success   : #13c24d !default;
$info      : #00aeff !default;
$warning   : #fea500 !default;
$danger    : #ff0003 !default;
$light     : #faf8f2 !default;
$dark      : #050d36;

// Body
$body-bg   : #ffffff;
$body-color: #777;

// Typography
$barlow: 'Barlow', sans-serif;
$font-family-base : 'Poppins', sans-serif;
$font-family-title : 'Poppins', sans-serif;
$headings-font-weight   : 600;

$font-size-base         : 0.9375rem !default;
$font-weight-base       : 400 !default;

$line-height-base    	: 1.6;
$h1-font-size           : 2.625rem;
$h2-font-size           : 2.25rem;
$h3-font-size           : 1.875rem;
$h4-font-size           : 1.5rem;
$h5-font-size           : 1.25rem;
$h6-font-size           : 1rem;

$text-muted : #AEAED5;
$colors     : () !default;

$heading-primary-size: 1.8rem;
$sub-heading-size    : 1.6rem !default;
$grid-gutter-width   : 30px;
$dropdown-lik-color  : $body-color;
$border       		 : #e1e1e1;
$headings-color      : #050d36 !default;
$grid-breakpoints    : (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1440);
$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px) !default;

$input-btn-focus-width: 0rem !default;
$border-radius-base   : 6px !default;
$shadow-color: rgba(160, 159, 202, 0.15);

// Other Color
$orange  : #ff8853;
$skyblue : #00aeff;
$gray  	 : #f3f4f8;
$yellow  : #ffa808;
$red     : #ff0003;
$green   : #029e76;
$blue    : #5543d1;
$pink  	 : #ff5e78;
$purple	 : #8007e6;

// Color Opacity
$primary-light: rgba($primary,0.1);
$secondary-light: rgba($secondary,0.1);
$success-light: rgba($success,0.1);
$warning-light: rgba($warning,0.1);
$danger-light: rgba($danger,0.1);
$info-light: rgba($info,0.1);
$dark-light: rgba($dark,0.1);
$white-light: rgba($light,0.1);
$yellow-light: rgba($yellow,0.1);

// border radius
$border-radius: .25rem !default;
$border-radius-lg: 1rem !default;
$border-radius-sm: .75rem !default;

:root{
	--primary: #{$primary};
	--secondary: #{$secondary};
	--gray: #{$gray};
	--primary-hover: #048091; // #186f61;
	--secondary-hover: #f64f40;
	--primary-dark: #ea4619;
	--rgba-secondary-1: #{rgba($secondary, 0.1)};
	--rgba-primary-1: #{rgba($primary, 0.1)};
	--rgba-primary-2: #{rgba($primary, 0.2)};
	--rgba-primary-3: #{rgba($primary, 0.3)};
	--rgba-primary-4: #{rgba($primary, 0.4)};
	--rgba-primary-5: #{rgba($primary, 0.5)};
	--rgba-primary-6: #{rgba($primary, 0.6)};
	--rgba-primary-7: #{rgba($primary, 0.7)};
	--rgba-primary-8: #{rgba($primary, 0.8)};
	--rgba-primary-9: #{rgba($primary, 0.9)};
	--font-family-base: #{$font-family-base};
	--font-family-title: #{$font-family-title};
	--border-radius-base: #{$border-radius-base};
	--title: #071c35;
}